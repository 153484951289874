import React, { useState, useEffect } from 'react'

const calculateTimeToHalloween = () => {
  const difference = +new Date(`10/31/2020`) - +new Date()
  const zeroPad = (num) => {
    return ('0' + num).slice(-2)
  }
  let timeLeft = {}
  if (difference > 0) {
    timeLeft = {
      days: zeroPad(Math.floor(difference / (1000 * 60 * 60 * 24))),
      hours: zeroPad(Math.floor((difference / (1000 * 60 * 60)) % 24)),
      minutes: zeroPad(Math.floor((difference / 1000 / 60) % 60)),
      seconds: zeroPad(Math.floor((difference / 1000) % 60)),
    }
  } else {
    timeLeft = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    }
  }
  return timeLeft
}

const CountDown = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeToHalloween())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeToHalloween())
    }, 1000)
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer)
  })

  return (
    <div>
      {/* <h2>Clock of Remorse</h2>
      <br /> */}
      <div className='clock'>
        {timeLeft.days}:{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
      </div>
    </div>
  )
}

export default CountDown
