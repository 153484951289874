import React from 'react'
import logo from '../../constants/pics/wifs-logo.png'
import { Link } from 'react-router-dom'

const Header = () => (
  <header className='App-header'>
    <Link to='/'>
      <img src={logo} className='App-logo' alt='logo' />
    </Link>
  </header>
)

export default Header
