import React, { useContext } from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../../server/firebase'
import { Link } from 'react-router-dom'
import myCluesContext from '../../server/myCluesContext'

const Home = () => {
  let myClues = useContext(myCluesContext)
  let i = 0
  //console.log('myClues:', myClues)
  return (
    <div>
      <p>
        <Link to={`/clue/cor`}>CLUE 0: clock of remorse</Link>
      </p>
      {myClues.map((clue) => {
        if (clue.req) i++
        return (
          clue.vis && (
            <p key={clue.id}>
              <Link to={`/clue/${clue.id}`}>
                {clue.req && `CLUE ${i}: `}
                {clue.title}
              </Link>
            </p>
          )
        )
      })}
    </div>
  )
}

export default compose(withFirebase)(Home)
