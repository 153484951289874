import React, { useEffect, useState } from 'react'
import { withFirebase } from '../../server/firebase'
import { pathSheet } from '../../server/firebase/sheets'

const Warning = ({ pathId, firebase }) => {
  const [warning, setWarning] = useState('')

  useEffect(() => {
    let ref = firebase.db.ref(`${pathSheet}/${pathId}/warning`)
    ref.on('value', (snapshot) => {
      let warning = snapshot.val()
      setWarning(warning)
    })
    return () => ref.off()
  }, [firebase, pathId])

  if (warning && warning.length) {
    return (
      <div className='Warning'>
        <div>
          {warning.split('\n').map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
          ))}
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}

export default withFirebase(Warning)
