import React, { useState, useContext } from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../../server/firebase'
import AuthUserContext from '../../server/userContext'
import morseCode from '../../constants/pics/morseCode.gif'

const Gateway = (props) => {
  let user = useContext(AuthUserContext)
  let [answer, setAnswer] = useState('')
  let [message, setMessage] = useState('')
  let [solved, setSolved] = useState(false)
  const [numTries, setNumTries] = useState(1)
  const [numLetters, setNumLetters] = useState(0)

  let handleSubmit = async (e) => {
    e.preventDefault()
    let lowerCase = answer.toLowerCase()
    if (lowerCase.length) {
      if (lowerCase === 'nightmares') {
        setMessage('Correct.')
        setSolved(true)
        try {
          await props.firebase.fs.doc(`users/${user.username}/`).update({
            passedGateway: true,
          })
        } catch (err) {
          console.error(err)
        }
      } else {
        setMessage('Try again :(')
        setNumTries((numTries + 1) % 3)
        if (numTries === 0) {
          setNumLetters(numLetters + 1)
        }
      }
    }
    setAnswer('')
  }
  const handleChange = (e) => {
    setAnswer(e.target.value)
  }

  return (
    <div className='Gateway'>
      <h2 className='clockTitle '>
        Clock of Remorse{' '}
        {solved && <span style={{ color: 'green' }}> -Complete</span>}
      </h2>
      <img
        src={morseCode}
        alt='gif of countdown clock at 00:00:00:00 flashing a message in Morse Code'
        className='Gateway-gif '
      ></img>
      <div>
        <p style={{ color: 'lightgray', textAlign: 'center' }}>
          Enter the clock's code below:
        </p>
        <p>
          <a href='https://pbs.twimg.com/media/EloRLDvWkAEIGCj?format=jpg&name=small'>
            hint
          </a>
        </p>
        {numLetters > 0 && (
          <p style={{ color: 'green' }}>{'nightmares'.slice(0, numLetters)}</p>
        )}
        <form style={{ display: 'inline' }} onSubmit={handleSubmit}>
          <input type='text' value={answer} onChange={handleChange} />
          <button type='submit'>Submit</button>
        </form>
        <p>{message}</p>
      </div>
    </div>
  )
}

export default compose(withFirebase)(Gateway)
