import React, { useEffect, useState, useContext } from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../../server/firebase'
import { clueSheet } from '../../server/firebase/sheets'
import myCluesContext from '../../server/myCluesContext'
import AuthUserContext from '../../server/userContext'
import { Link } from 'react-router-dom'

const useClue = (id, firebase) => {
  const [clue, setClue] = useState({ isLoading: true })
  useEffect(() => {
    let ref = firebase.db.ref(`${clueSheet}/${id}`)
    ref.on('value', (snapshot) => {
      let answers = snapshot.val()
      //console.log(answers)
      answers = answers
        ? answers.answer.map((val) => (isNaN(val) ? val : String(val)))
        : []
      setClue({ ...snapshot.val(), isLoading: false, answer: answers })
    })
    return () => ref.off()
  }, [firebase, id])
  return clue
}

const useMyAnswers = (username, clueId, firebase) => {
  const [myAnswers, setMyAnswers] = useState({ ans: [], isLoading: true })

  useEffect(() => {
    const unsubscribe = firebase.fs
      .collection(`users/${username}/myClues/${clueId}/myAnswers`)
      .onSnapshot((snapshot) => {
        const answers = snapshot.docs.map((ans) => {
          return ans.id
        })
        setMyAnswers({ ans: answers, isLoading: false })
      })
    return () => unsubscribe()
  }, [firebase, username, clueId])
  return myAnswers
}

const Clue = (props) => {
  let user = useContext(AuthUserContext)
  let myClues = useContext(myCluesContext)
  let myClue = myClues.find((elem) => elem.id === props.match.params.clueId)
  let clue = useClue(props.match.params.clueId, props.firebase)
  const [numTries, setNumTries] = useState(1)
  const [numLetters, setNumLetters] = useState(0)

  let myAnswers = useMyAnswers(
    user.username,
    props.match.params.clueId,
    props.firebase
  )

  let [answer, setAnswer] = useState('')
  let [message, setMessage] = useState('')
  let [solved, setSolved] = useState(props.match.params.clueId === 'cor')

  let handleSubmit = async (e) => {
    e.preventDefault()
    let lowerCase = answer.toLowerCase()
    if (lowerCase.length) {
      if (clue.answer.includes(lowerCase)) {
        if (myAnswers.ans.includes(lowerCase)) {
          if (solved) {
            setMessage(`Already complete.`)
          } else {
            setMessage("You've already got that one!")
          }
        } else {
          if (myAnswers.ans.length + 1 < clue.numNeeded) {
            setMessage('Correct.')
          } else {
            setMessage('Correct.')
            setSolved(true)
          }
          try {
            let promises = []
            promises.push(
              props.firebase.fs
                .collection(
                  `users/${user.username}/myClues/${String(clue.id)}/myAnswers/`
                )
                .doc(lowerCase)
                .set({ lowerCase: true })
            )
            promises.push(
              props.firebase.fs
                .doc(`users/${user.username}/myClues/${String(clue.id)}`)
                .update({
                  numAns: props.firebase.increment,
                })
            )
            if (myAnswers.ans.length + 1 === clue.numNeeded) {
              promises.push(
                props.firebase.fs
                  .collection(`users/${user.username}/solvedClues`)
                  .doc(String(clue.id))
                  .set({ id: String(clue.id) })
              )
              if (myClue.req) {
                promises.push(
                  props.firebase.fs
                    .doc(`users/${user.username}/batch/currentBatch`)
                    .update({
                      numHave: props.firebase.increment,
                    })
                )
              }
            }

            await Promise.all(promises)
          } catch (err) {
            console.error(err)
          }
        }
      } else {
        if (solved) setMessage('Already Complete.')
        else {
          setMessage('Try again :(')
          setNumTries((numTries + 1) % 3)
          if (numTries === 0) {
            setNumLetters(numLetters + 1)
          }
        }
      }
    }
    setAnswer('')
  }
  const handleChange = (e) => {
    setAnswer(e.target.value)
  }

  console.log('clue:', clue)
  //console.log('myClue', myClue)
  // console.log('myAnswers', myAnswers)
  // console.log('solved', solved)
  if (
    props.match.params.clueId !== 'cor' &&
    clue.isLoading === false &&
    (typeof clue.title === 'undefined' || typeof myClue === 'undefined')
  ) {
    return (
      <div>
        <h2>You seem lost!</h2>
        <a href='/'>Home</a>
      </div>
    )
  } else if (
    myClue &&
    myClue.vis === false &&
    props.match.params.clueId !== 'cor'
  ) {
    return (
      <div>
        <h2>No Cheating!</h2>
        <a href='/'>Home</a>
      </div>
    )
  } else if (clue.isLoading === true) {
    return <div></div>
  } else {
    if (
      !solved &&
      myAnswers.isLoading === false &&
      myAnswers.ans.length >= clue.numNeeded &&
      clue.numNeeded > 0
    ) {
      setSolved(true)
    }
    return (
      <div className='clue-page center'>
        <h2>
          {clue.title}{' '}
          {solved && <span style={{ color: 'green' }}> -Complete</span>}
        </h2>

        {clue.textLeft &&
          clue.textLeft.split('\n').map((paragraph, i) => (
            <p key={i} style={{ textAlign: 'left' }}>
              {paragraph}
            </p>
          ))}
        {clue.textCenter &&
          clue.textCenter
            .split('\n')
            .map((paragraph, i) => <p key={i}>{paragraph}</p>)}
        {clue.linkURL && (
          <div>
            <a href={clue.linkURL} target='_blank' rel='noopener noreferrer'>
              {clue.linkText}
            </a>
          </div>
        )}
        {[1, 2, 3, 4, 5].map((num) => {
          let img = 'imgURL' + String(num)
          let altText = 'imgAltText' + String(num)
          if (clue[img]) {
            return (
              <div key={num}>
                <img
                  src={clue[img]}
                  className='clue-img'
                  alt={clue[altText]}
                ></img>
              </div>
            )
          }
        })}
        {[1, 2, 3, 4, 5].map((num) => {
          let ytID = 'youtubeID' + String(num)
          if (clue[ytID]) {
            return (
              <iframe
                className='clue-img'
                title={clue[ytID]}
                width='560'
                height='315'
                src={`https://www.youtube.com/embed/${clue[ytID]}`}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            )
          }
        })}
        {[1, 2].map((num) => {
          let img = 'underImgURL' + String(num)
          let altText = 'underImgAltText' + String(num)
          if (clue[img]) {
            return (
              <div key={num}>
                <img
                  src={clue[img]}
                  className='clue-img'
                  alt={clue[altText]}
                ></img>
              </div>
            )
          }
        })}
        {clue.downloadURL && clue.downloadURL.length && (
          <div>
            <a
              href={clue.downloadURL}
              download
              target='_blank'
              rel='noopener noreferrer'
            >
              Download
            </a>
            <br />
          </div>
        )}
        {clue.text2 &&
          clue.text2
            .split('\n')
            .map((paragraph, i) => <p key={i}>{paragraph}</p>)}
        <br />
        {clue.numNeeded > 0 && (
          <div>
            {numLetters > 0 && (
              <p style={{ color: 'green' }}>
                {clue.answer[0].slice(0, numLetters)}
              </p>
            )}
            <form style={{ display: 'inline' }} onSubmit={handleSubmit}>
              <input type='text' value={answer} onChange={handleChange} />
              <button type='submit'>Submit</button>
            </form>
          </div>
        )}
        {clue.numNeeded > 1 && (
          <p>{`${myAnswers.ans.length ? myAnswers.ans.length : 0}/${
            clue.numNeeded
          }`}</p>
        )}
        <p>{message}</p>
        <Link to='/'>Back</Link>
        <br />
      </div>
    )
  }
}

export default compose(withFirebase)(Clue)
