import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/'
//import * as functions from 'firebase/functions'
import config from './config'
import { userSheet } from './sheets'
import password from './password'

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.database()
    this.fs = app.firestore()
    this.fs.enablePersistence({ synchronizeTabs: true }).catch(function (err) {
      console.error(err)
    })
    this.increment = app.firestore.FieldValue.increment(1)
  }

  tryToSignIn = async (username) => {
    username = username.toLowerCase()
    let email = username + '@worldInferno.com'
    let dbRef = this.db.ref(userSheet + '/' + username)
    // console.log(dbRef)
    try {
      let snapshot
      await dbRef
        .once('value')
        .then((ss) => {
          snapshot = ss
        })
        .catch((err) => console.error(err))
      // console.log(snapshot)
      if (snapshot.exists()) {
        this.auth
          .createUserWithEmailAndPassword(email, password)
          .catch((err) => {
            if (err.code === 'auth/email-already-in-use') {
              this.auth
                .signInWithEmailAndPassword(email, password)
                .catch((err) => {
                  console.error(err)
                })
            } else {
              console.error(err)
            }
          })
      } else {
        throw Error('Not a valid username.')
      }
    } catch (err) {
      throw Error(err)
    }
  }

  onAuthStateChange = (callback) => {
    let authState = this.auth.onAuthStateChanged((user) => {
      if (user) {
        //console.log(user)
        let username = user.email.split('@')[0]
        callback({
          loggedIn: true,
          username,
          isLoading: false,
        })
      } else {
        callback({ loggedIn: false, isLoading: false })
      }
    })
    return authState
  }
}

export default Firebase
