import React, { useState, useEffect } from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {
  Header,
  SignIn,
  Home,
  Clue,
  Footer,
  Warning,
  CountDown,
  Gateway,
} from './components'
import { withFirebase } from './server/firebase'
import { AuthUserProvider } from './server/userContext'
import { MyCluesProvider } from './server/myCluesContext'

function App(props) {
  const [user, setUser] = useState({ loggedIn: false, isLoading: true })
  useEffect(() => {
    const unsubscribe = props.firebase.onAuthStateChange(setUser)
    return () => {
      return unsubscribe()
    }
  }, [props.firebase])

  const [myLevelAndPath, setMyLevelAndPath] = useState({
    level: 0,
    path: null,
    phase: 0,
    passedGateway: false,
    isLoading: true,
  })
  useEffect(() => {
    if (user.loggedIn) {
      const unsubscribe = props.firebase.fs
        .doc(`users/${user.username}`)
        .onSnapshot((snapshot) => {
          let level = snapshot.get('level')
          let path = snapshot.get('pathId')
          let phase = isNaN(snapshot.get('phase')) ? 0 : snapshot.get('phase')
          let passedGateway = snapshot.get('passedGateway')
          let isLoading = false
          setMyLevelAndPath({ level, path, phase, passedGateway, isLoading })
        })
      return () => unsubscribe()
    }
  }, [user.loggedIn, user.username, user.phase, props.firebase])

  const [globalPhase, setGlobalPhase] = useState({ phase: 0, isLoading: true })
  useEffect(() => {
    const unsubscribe = props.firebase.fs
      .doc('global/phase')
      .onSnapshot((snapshot) => {
        setGlobalPhase({ phase: snapshot.get('currPhase'), isLoading: false })
      })
    return () => unsubscribe()
  }, [props.firebase])
  const [sitePhase, setSitePhase] = useState(0)
  useEffect(() => {
    if (user.loggedIn && !isNaN(myLevelAndPath.phase)) {
      setSitePhase(Math.max(globalPhase.phase, myLevelAndPath.phase))
    } else {
      setSitePhase(globalPhase.phase)
    }
  }, [globalPhase.phase, myLevelAndPath.phase, user.loggedIn])

  const [solvedClues, setSolvedClues] = useState(new Set())
  useEffect(() => {
    if (user.loggedIn) {
      const unsubscribe = props.firebase.fs
        .collection(`users/${user.username}/solvedClues`)
        .onSnapshot((querySnap) => {
          let solvedClue = new Set()
          querySnap.forEach((clue) => {
            solvedClue.add(clue.id)
          })
          setSolvedClues(solvedClue)
        })
      return () => unsubscribe()
    }
  }, [user, props.firebase])

  const [myClues, setMyClues] = useState([])
  useEffect(() => {
    if (user.loggedIn && myLevelAndPath.path) {
      const unsubscribe = props.firebase.fs
        .collection(`paths/${myLevelAndPath.path}/clues`)
        .orderBy('order')
        .onSnapshot((snapshot) => {
          const clues = snapshot.docs.map((doc) => {
            let clue = doc.data()
            let gotToBatch = clue.batch <= myLevelAndPath.level
            // console.log('gotToBatch', gotToBatch)
            let unlocked
            if (!clue.cluesNeeded || clue.cluesNeeded.length === 0) {
              unlocked = true
            } else {
              let cluesNeeded = clue.cluesNeeded.replace(/\s+/g, '').split(',')
              unlocked = cluesNeeded.every((val) => solvedClues.has(val))
            }
            let isStage2 = sitePhase >= 2
            return {
              ...clue,
              vis: gotToBatch && unlocked && (clue.batch > 2 ? isStage2 : true),
            }
          })
          setMyClues(clues)
        })
      return () => unsubscribe()
    }
  }, [
    user.loggedIn,
    props.firebase,
    user.username,
    myLevelAndPath,
    solvedClues,
    sitePhase,
  ])
  //console.log(globalPhase.phase, myLevelAndPath.phase, sitePhase)
  console.log(myLevelAndPath.level)
  console.log(myClues)
  return (
    <div>
      {user.isLoading ||
      globalPhase.isLoading ||
      setMyLevelAndPath.isLoading ? (
        <div className='App'>
          <div className='SignIn-Background'></div>
        </div>
      ) : user.loggedIn ? (
        <div>
          {sitePhase === 0 ? (
            <div className='App'>
              <div className='SignIn-Background'>
                <CountDown />
                <Footer phase={0} />
              </div>
            </div>
          ) : (
            <div>
              {myLevelAndPath.passedGateway === true ? (
                <div className='App'>
                  <AuthUserProvider value={user}>
                    {myLevelAndPath.path && (
                      <Warning pathId={myLevelAndPath.path} />
                    )}
                    <MyCluesProvider value={myClues}>
                      <Router>
                        <Header />
                        <div className='App-body'>
                          <div className='Content-wrap'>
                            <Switch>
                              <Route exact path='/' component={Home} />
                              <Route
                                exact
                                path='/clue/:clueId'
                                component={Clue}
                              />
                              <Route path='/gateway' component={Gateway} />
                            </Switch>
                          </div>
                        </div>
                      </Router>
                    </MyCluesProvider>
                    <Footer />
                  </AuthUserProvider>
                </div>
              ) : (
                <div>
                  <div className='App'>
                    <AuthUserProvider value={user}>
                      <div className='Content-wrap'>
                        <Gateway />
                        <Footer />
                      </div>
                    </AuthUserProvider>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className='App'>
          <div className='SignIn-Background'>
            <div className='App-body'>
              <Router>
                <SignIn />
              </Router>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default withFirebase(App)
