import React from 'react'
import { withFirebase } from '../../server/firebase'

const Footer = ({ phase = 2, ...props }) => {
  //console.log(props)
  let handleClick = (e) => {
    props.firebase.auth.signOut()
  }
  return (
    <div className='App-footer'>
      {phase > 0 && (
        <span style={{ color: '#818181' }}>
          If you try enough times, the code will be revealed. For technical
          difficulties, contact nosliwpilf.10.31.2020@gmail.com
        </span>
      )}
      <div
        style={{ float: 'right', color: '#333333', cursor: 'pointer' }}
        onClick={handleClick}
      >
        {`Sign Out`}
      </div>
    </div>
  )
}

export default withFirebase(Footer)
