import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../../server/firebase'

const SignIn = (props) => {
  const [state, setState] = useState({
    username: '',
    error: null,
  })
  const handleChange = (e) => {
    const { id, value } = e.target
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.firebase
      .tryToSignIn(state.username.toLowerCase())
      .then(() => {
        setState({ username: '', error: null })
        props.history.push('/')
      })
      .catch((error) => {
        setState({ error })
        //console.log(state)
      })
  }

  // const signOut = (e) => {
  //   props.firebase.auth.signOut()
  // }

  return (
    <div>
      {/* <h3 style={{ color: '#B5D3E7' }}></h3> */}
      <form onSubmit={handleSubmit}>
        <input
          type='text'
          className='form-control'
          id='username'
          value={state.username}
          onChange={handleChange}
        />
        <div>
          <button type='submit'>Play my card</button>
        </div>
        {state.error && <p style={{ color: 'red' }}>{state.error.message}</p>}
      </form>
      {/* <button onClick={signOut}>Sign Out</button> */}
    </div>
  )
}

export default compose(withRouter, withFirebase)(SignIn)
