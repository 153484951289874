export default {
  apiKey: 'AIzaSyATb6lfSuJ4H2JOVUjzatfPQP9up50x0EY',
  authDomain: 'hallowmas-6d6b2.firebaseapp.com',
  databaseURL: 'https://hallowmas-6d6b2.firebaseio.com',
  projectId: 'hallowmas-6d6b2',
  storageBucket: 'hallowmas-6d6b2.appspot.com',
  messagingSenderId: '284738315628',
  appId: '1:284738315628:web:0b1aaeba073d9c9f37ff49',
  measurementId: 'G-86RVX77WB3',
}
